import React, {useContext, useState} from 'react'
import UserContext from '../context/user'
import {allEmployees} from '../../employees'
import {Typeahead} from 'react-bootstrap-typeahead'
import Header from '../components/header'
import {SelfReviewForm} from '../components/self-review'
import Section from '../components/section'
import {TypeForm, PeerReviewForm} from "../components/typeform"
import Alert from "react-bootstrap/alert"

import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-bootstrap-typeahead/css/Typeahead.css'


const PeerReviewPage = () => {
  const user = useContext(UserContext)

  const [selected, setSelected] = useState(null)

  const coworkers = allEmployees.filter((employee) => {
    return true //!user.getHasReviewed(employee.id)
  })

  const onSubmission = function() {
    document.getElementById("thankYouPrompt").style.display="block";
  }

  return (
    <>
      <Header/>
      <br/>

      <div style={{color: 'black', fontSize: '24px', fontWeight: 'bold'}}>
      Peer Review
      </div>
      <br/>

      <Typeahead
          id={'employees'}
          labelKey={'name'}
          filterBy={['name', 'id', 'nickname']}
          options={coworkers}
          placeholder={`Enter a coworker's name to send a review of them to their manager...`}
          autoFocus={true}
          onChange={(selected) => {
            setSelected(selected[0])
          }}
      />
      <PeerReviewForm
        peer={selected}
        onSubmit={onSubmission}
      />

      <br/>
      <div id="thankYouPrompt" style={{color: 'black', fontSize: '24px', fontWeight: 'bold', display: 'none'}}>
      You're all done! Please feel free to review as many of your peers as you like by using the "Typeahead" field to search for a new name.
      </div>
    </>
  )
}

export default PeerReviewPage
